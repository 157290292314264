<template>
  <div class="grid-container">
    <div class="logo">
      <img src="@/assets/icons/logo.png" />
    </div>
    <div class="container">
        <div class="alert" v-if="errorMsg.length > 0">
            <h1>Alert</h1>
            <h3>{{ errorMsg }}</h3>
        </div>
        <form @submit.prevent="submitOTP" class="form shadow">
            <h1>Reset Your Password</h1>
            <p class="instruction">Strong password include numbers, letters and punctuation marks.</p>

            <input
                type="password"
                autocomplete="true"
                placeholder="New password"
                v-model="model.password"
                required
            />

            <input
                type="password"
                autocomplete="true"
                placeholder="Confirm new password"
                v-model="model.rePassword"
                required
            />

            <input type="submit" value="Continue" />
        </form>
    </div>
</div>
</template>

<script>
export default {
    name: "ResetPassword",
    data() {
        return {
            model: {
                password: "",
                rePassword: "",
            },
            errorMsg: ""
        }
    },
    methods: {
        async submitOTP() {
            if(this.isPasswordValidAndSetErrorMsg()) {
                if(this.model.password !== '' && this.model.rePassword !== '') {
                    if (
                        this.model.rePassword.length < 6 ||
                        this.model.rePassword.length > 255) {
                        this.errorMsg = "Password should be between 6 and 255 characters."
                        return
                    } else if(this.model.password !== this.model.password) {
                        this.errorMsg = "Both passwords must be the same."
                    } else {
                        const res = await this.$api.resetPassword(this.model.password, this.$route.query.token);
                        if(res.success) {
                            this.$router.push({ path: '/sign-in' })
                        } else {
                            this.errorMsg = res.msg;
                        }
                    }
                }
            }
        },
        isPasswordValidAndSetErrorMsg() {
            const trimedPassword = this.model.password.trim();
            if (typeof trimedPassword !== 'string') return false;
            if (trimedPassword.length < 6 || trimedPassword.length > 50) {
                this.errorMsg = "Password length must be between 6 and 50 characters.";
                return false
            };
            if (!trimedPassword.trim().length) {
                this.errorMsg = "Password is a required field.";
                return false
            };
            if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$:%^&*()_+]*$/.test(trimedPassword)) {
                this.errorMsg = "Password must contain at least one lowercase and uppercase letter and a number. Password can contain only this special characters !@#$:%^&*()_";
                return false
            };
            this.errorMsg = "";
            return true;
        }
    }
}
</script>

<style lang="scss" scoped>
.grid-container {
        @include gradient;

        display: grid;
        grid-template-columns: 0.9fr 1.2fr 0.9fr;
        grid-template-rows: auto 15% auto 30%;
        gap: 0px 0px;
        grid-template-areas:
            ". . ."
            ". logo ."
            ". fPassword ."
            ". . .";

        @include center;
        text-align: center;
        height: 100vh;

        @include respond-to("medium") {
            grid-template-columns: 0.1fr auto 0.1fr;
            gap: 20px 20px;
        }
    }

    .logo {
        display: flex;
        align-items: flex-end;

        grid-area: logo;
        @include center;

        img {
            width: 200px;
            @include white-filter;
        }
    }

    .container {
        width: 100%;
        height: 100%;
        max-width: 500px;
        grid-area: fPassword;
        @include center;

        .register-link {
            text-decoration: none;
            color: #fff;
        }

        .form {
            padding: 5%;
            margin-bottom: 15px;

            @include box-shadow;
            background-color: #242326;
            border: none;
            border-radius: 15px;

            h1 {
                @extend .noselect;
                margin-top: 0;
                margin-bottom: 20px;

                color: #f1f1fe;
                font-size: 1.3em;
                padding-left: 15px;
                text-align: left;
            }

            .instruction {
                color: #fff;
                padding: 0 15px 15px 15px;
                text-align: left;
            }

            // Inputs
            input {
                display: block;
                box-sizing: content-box;
                width: 90%;
                max-width: 90%;
                @include center;
                margin-top: 15px;

                border: none;
                border-radius: 15px;
                background-color: #f1f1fe;
                color: #090909;

                @include placeholder {
                    color: #8b8a92;
                    font-size: 1.1em;
                }
                animation: fadeIn 0.3s, popup 0.4s;
            }
                
            input[type="password"] {
                padding: 15px;

                &:focus {
                    background-color: #f1f1fe;
                }
            }

            input[type="submit"] {
                @extend .pointer;
                @extend .noselect;
                @include gradient;

                width: 30%;
                min-width: 6em;
                padding: 15px;

                color: #ffffff;
                font-size: 1em;

                &:hover {
                    @include popup(1.04);
                }
            }
        }

        .alert {
            @include center;
            @include box-shadow;

            animation: fadeIn 0.7s, popup 0.7s, rotate 0.4s;

            position: fixed;
            top: 10%;
            right: 10%;

            background-color: var(--orange);
            border-radius: 15px;
            padding: 10px;

            h1 {
            color: #242326;
            font-size: 1.5em;
            }
            h3 {
            color: #242326;
            font-size: 1.2em;
            }
        }
  }
</style>